import PropTypes from 'prop-types'
import React from 'react'

import { Text } from 'shared/Typography'
import classNames from 'classnames'
import { IT, JP } from 'constants/countries'
import AddAndGoToCart from 'containers/AddAndGoToCart'
import { Button } from 'shared/Button'

import { Content, Header, Title, Description, DiscountBadge, Footer, OldPrice, PriceAndButton } from './styles'

const PromoLayout = ({
  title,
  description,
  discount,
  scrollToProducts,
  isBlackColor,
  price,
  currentPrice,
  buttonPurchaseText,
  buttonScrollText,
  actionType,
  currentTestName,
  isDefaultWgs,
  currentGoodsItem,
  locale,
  currentSlide,
  mobile,
  isZeroPrice,
}) => {
  return (
    <Content>
      <Header>
        <>
          <Title
            forwardedAs='h1'
            weight='medium'
            size={['80/90', '65/70', '30/40']}
            markColor={currentSlide?.styles.markColor}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {description && (
            <Description
              forwardedAs='p'
              size={['18/28', '18/28', '16/25']}
              linkColor={currentSlide?.styles.markColor}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </>
      </Header>

      <Footer
        layout={currentSlide?.layout}
        buttonBg={currentSlide?.styles?.buttonBg}
      >
        {actionType === 'purchase' && currentGoodsItem && (
          <>
            {currentTestName && !mobile && (
              <Text
                className={classNames({
                  'h-color-D100': isDefaultWgs,
                })}
                weight='medium'
                size='18/28'
              >
                {currentTestName}
              </Text>
            )}

            {currentGoodsItem.price !== currentGoodsItem.currentPrice && !isZeroPrice && (
              <OldPrice layout={currentSlide?.layout}>
                <Text
                  className={classNames({
                    'h-color-D50': isBlackColor || isDefaultWgs,
                    'h-color-W50': !isBlackColor && !isDefaultWgs,
                  })}
                  as='h5'
                  weight='medium'
                  size={mobile ? '18/24' : '22/30'}
                  decoration='line-through'
                >
                  {price}
                </Text>

                {discount !== 0 && (
                  <DiscountBadge
                    size={['14/20', '14/20', '12/14']}
                    weight='medium'
                    bg={currentSlide.styles.badgeBg}
                    color={currentSlide.styles.badgeColor}
                  >
                    -{discount}%
                  </DiscountBadge>
                )}
              </OldPrice>
            )}

            <PriceAndButton layout={currentSlide?.layout}>
              {![JP, IT].includes(locale) && !isZeroPrice && (
                <Text
                  className={classNames({
                    'h-color-D100': isDefaultWgs,
                  })}
                  size={mobile ? '25/30' : '35/40'}
                  weight='medium'
                  layout={currentSlide?.layout}
                >
                  {currentPrice}
                </Text>
              )}

              <AddAndGoToCart
                key={currentSlide?.goodsType}
                goods={[{
                  type: currentSlide?.goodsType === 'microbiome' ? 'biome' : currentSlide?.goodsType,
                  isSubscription: false,
                }]}
                buttonProps={{
                  color: currentSlide?.styles.buttonBg, size: mobile ? 'mobile' : 'desktop',
                  disabled: isZeroPrice,
                }}
                ButtonComponent={Button}
                forceMultipleAdd
              >
                {buttonPurchaseText}
              </AddAndGoToCart>
            </PriceAndButton>
          </>
        )}

        {actionType === 'scroll' && (
          <Button
            color={currentSlide?.styles.buttonBg}
            size={mobile ? 'mobile' : 'desktop'}
            onClick={scrollToProducts}
          >
            {buttonScrollText}
          </Button>
        )}
      </Footer>
    </Content>
  )
}

PromoLayout.propTypes = {
  title: PropTypes.string.isRequired,
  discount: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  currentTestName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
  currentPrice: PropTypes.string.isRequired,
  buttonPurchaseText: PropTypes.string.isRequired,
  buttonScrollText: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  currentSlide: PropTypes.object.isRequired,
  currentGoodsItem: PropTypes.object.isRequired,
  isDefaultWgs: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  isBlackColor: PropTypes.bool.isRequired,
  scrollToProducts: PropTypes.func.isRequired,
  isZeroPrice: PropTypes.bool.isRequired,
}

export default PromoLayout
